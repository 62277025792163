import React, { Component } from 'react';
import { Container, Menu, Responsive, Icon, Image, Button } from 'semantic-ui-react';

import styled from 'styled-components';
import logo from "../static/images/updated-colors-bf-logo.png";
import whiteLogo from "../static/images/white-logo-tagline.png";

const VertMenu = styled(Menu)`
  display: ${props => props.verticalMenu ? 'none' : 'initial'} !important;
  border: none !important;

  .item {
    background: #CD5B49 !important;
    color: white !important;
    text-align: center;
    font-size: 1.43em;
    padding-top: 1.1em !important;
    padding-bottom: 1.1em !important;
  }

  .active {
    text-decoration: underline !important;
  }
`;

class VerticalMenu extends Component {
  state = {
    activeItem: 'home'
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    return (
      <VertMenu vertical fluid borderless centered verticalMenu={this.props.displayVertMenu}>
        <Menu.Item fitted='vertically' onClick={this.props.handleClose} position='right' style={{textAlign: 'right'}}>
          <Icon name='close' fitted/>
        </Menu.Item>

        <Menu.Item
          as='a'
          name='home'
          onClick={this.handleItemClick}
          active={activeItem === 'home'}
          href='/'
        >
          <Image centered src={whiteLogo} width='55px' alt="BestFit Logo" />
        </Menu.Item>

        <Menu.Item
          as='a'
          name='about'
          onClick={this.handleItemClick}
          active={activeItem === 'about'}
          href='https://best-fit.app/about'
        >
          About Us
        </Menu.Item>

        <Menu.Item
          as='a'
          name='faq'
          onClick={this.handleItemClick}
          active={activeItem === 'faq'}
          href='https://best-fit.app/faq'
        >
          FAQs
        </Menu.Item>

        <Menu.Item
          as='a'
          name='blog'
          onClick={this.handleItemClick}
          active={activeItem === 'blog'}
          href='https://blog.best-fit.app'
        >
          Blog
        </Menu.Item>

        <IconRow />
        <Menu.Item
          as='a'
          name='login'
          onClick={this.handleItemClick}
          active={activeItem === 'login'}
          href='https://best-fit.app/login'
          style={{display: 'none'}}
        >
          Log in
        </Menu.Item>

        <Menu.Item compact
          as='a'
          name='resources'
          onClick={this.handleItemClick}
          active={activeItem === 'resources'}
          fitted
          href='https://resources.best-fit.app'
          style={{ fontWeight: '500 !important' }}
          target='_blank'
        >
          <MobileSignUpBtn compact inverted>Explore Resources!</MobileSignUpBtn>
        </Menu.Item>

        <Menu.Item
          as='a'
          name='signup'
          onClick={this.handleItemClick}
          active={activeItem === 'signup'}
          href='/signup'
        >
          <MobileSignUpBtn compact inverted >Partner With Us</MobileSignUpBtn>
        </Menu.Item>

      </VertMenu>
    )
  };
};
const MobileSignUpBtn = styled(Button)`
  font-size: 20px !important;
  width: 60%;
  color: white !important;
  font-weight: 400 !important;
`;

const IconRow = styled(({ className }) => (
  <Menu.Item as='IconGroup' className={className}>
    <Icon name='circle' size='tiny' style={{ color: '#FFDB5C', margin: '0 1em' }} />
    <Icon name='circle' size='tiny' style={{ color: '#15919B', margin: '0 1em' }} />
    <Icon name='circle' size='tiny' style={{ color: '#F8A055', margin: '0 1em' }} />
  </Menu.Item>
))`
  display: flex !important;
  justify-content: center;
  align-items: center;

`;

const HamburgerNav = ( props ) => (
  <Container style={{backgroundColor: 'transparent', padding: '1em 0 1em 1em' }}>
    <Menu borderless secondary>
      <Menu.Item fitted='horizontally' >
        <Image rounded size='mini' src={logo} href='/' alt="BestFit Logo" style={{ maxWidth: '24px' }}/>
      </Menu.Item>
      <Menu.Item onClick={props.handleMenu} position='right'>
        <Icon name='sidebar' size='big' style={{ color: '#CD5B49', margin: 0 }}/>
      </Menu.Item>
    </Menu>
  </Container>
);
class MobileNavigation extends Component {
  state = {
    hideMenu: true
  };

  handleMenu = () => this.setState({ hideMenu: (!this.state.hideMenu) });
  render () {
    const { hideMenu } = this.state;

    return (
      <Responsive maxWidth={767}>
        {hideMenu 
          ? <HamburgerNav handleMenu={this.handleMenu} /> 
          : <VerticalMenu displayVertMenu={hideMenu} handleClose={this.handleMenu} />
        }
      </Responsive>
    )
  };
};

export default MobileNavigation;