import React, { Component } from 'react';
import { Responsive, Container, Menu, Button, Image, Header } from 'semantic-ui-react';
import styled from 'styled-components';
// import HubspotForm from 'react-hubspot-form';
import logo from "../static/images/updated-colors-bf-logo.png";
import SignUpForm from './SignUpForm';

// const LinkedItem = ({ children, ...props }) => (
//   <Menu.Item as={Link} activeClassName='active' {...props}>{children}</Menu.Item>
// )

const FullMenu = styled(Menu)`
  font-size: 16px !important;
  font-weight: 500 !important;

  .item {
    color: #CD5B49 !important;
  }
  .active, .item:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }
`;

const SignUpHeader = styled(Header)`
  color: #CD5B49 !important;
  text-align: center;
  margin-bottom: .5em !important;
`;

const SignUpBtn = styled(Button)`
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  border: none !important;
  
  padding-left: 55px !important;
  padding-right: 55px !important;
  background-color: #CD5B49 !important;
  color: white !important;
  &#signupButton {
    box-shadow: 0px 0px 0px 1px #CD5B49 inset !important;
  }

  :hover {
    background-color: white !important;
    color: #CD5B49 !important;
  }
`;

const LogInBtn = styled(Button)`
  font-size: 16px !important;
  color: #CD5B49 !important;
  font-weight: 500 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-color: blue !important;
  &#loginButton {
    box-shadow: 0px 0px 0px 1px #CD5B49 inset !important;
  }
  :hover {
    background-color: #CD5B49 !important;
    color: white !important;
  }
`;
class TabletDesktopNavigation extends Component {
  state = {
    activeItem: 'home'
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  render() {
    const { activeItem } = this.state;

    return (
    <div>
      <Responsive minWidth={768}>
        <Container style={{ backgroundColor: 'transparent', marginBottom: '30px'}}>
          <FullMenu borderless secondary>
            <Menu.Menu position='left'>
              <Menu.Item
                as='a'
                name='home'
                onClick={this.handleItemClick}
                active={activeItem === 'home'}
                href='/'
                fitted='horizontally'
              >
                <Image src={logo} height='55px' floated='left' alt="BestFit Logo" />
              </Menu.Item>
              <Menu.Item
                as='a'
                name='about'
                onClick={this.handleItemClick}
                active={activeItem === 'about'}
                href='https://best-fit.app/about'
              >
                About Us
              </Menu.Item>

                <Menu.Item
                  as='a'
                  name='faq'
                  onClick={this.handleItemClick}
                  active={activeItem === 'faq'}
                  href='https://best-fit.app/faq'
                >
                  FAQs
              </Menu.Item>

              <Menu.Item
                as='a'
                name='blog'
                onClick={this.handleItemClick}
                active={activeItem === 'blog'}
                href='https://blog.best-fit.app'
              >
                Blog
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position='right'>
              <Menu.Item
                as='a'
                name='login'
                onClick={this.handleItemClick}
                active={activeItem === 'login'}
                fitted
                href='https://best-fit.app/login'
                style={{ display: 'none' }}
              >
                <LogInBtn inverted color="red">Log In</LogInBtn>
              </Menu.Item>

              <Menu.Item
                as='a'
                name='resources'
                onClick={this.handleItemClick}
                active={activeItem === 'resources'}
                fitted
                href='https://resources.best-fit.app'
                style={{ fontWeight: '500 !important' }}
                target='_blank'
              >
                <LogInBtn inverted id="loginButton">Explore Resources!</LogInBtn>
              </Menu.Item>

              <Menu.Item
                as='a'
                name='signup'
                onClick={this.handleItemClick}
                active={activeItem === 'signup'}
                fitted
              >
                <SignUpForm trigger={
                  <SignUpBtn inverted id="signupButton">Partner With Us</SignUpBtn>
                } />
              </Menu.Item>
            </Menu.Menu>
          </FullMenu>
        </Container>
      </Responsive>
    </div>
    )
  }
}

export default TabletDesktopNavigation;

export { LogInBtn, SignUpHeader };