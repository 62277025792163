import React from 'react';
// import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const FormWrapper = styled.div`
font-family: Poppins !important;
h2 {
  display: none !important;
}
#mc_embed_signup {
  background: transparent !important;
}
.indicates-required {
  display: none;
}
.mc-field-group {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;

  input {
    font-family: Poppins;
  }
  ul label{
    font-weight: 400 !important;
    margin-left: 5px;
  }
  input, select {
    border-radius: 19px !important;
    padding: 8px 10px !important;

  }
  select {
    width: 100%;
    background: transparent;
    font-family: Poppins;
  }
  &.size1of2 {
    display: none !important;
  }
}
.clear {
  .button {
    border-radius: 23px !important;
    background: #CD5B49 !important;
    margin: 0 auto !important;
    display: block !important;
    min-width: 200px;
    font-family: Poppins;
    padding: 10px 22px !important;
    height: 46px !important;
    line-height: 16px !important;
  }
}
`;

const partnerHtml = `<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://app.us19.list-manage.com/subscribe/post?u=7c623c4916e3b4c6839e07a36&amp;id=9ab706cd99" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	<h2>Subscribe</h2>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="mc-field-group">
	<label for="mce-FNAME">First Name </label>
	<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
	<label for="mce-LNAME">Last Name </label>
	<input type="text" value="" name="LNAME" class="" id="mce-LNAME">
</div>
<div class="mc-field-group">
	<label for="mce-MMERGE6">University or Organization Name  <span class="asterisk">*</span>
</label>
	<input type="text" value="" name="MMERGE6" class="required" id="mce-MMERGE6">
</div>
<div class="mc-field-group input-group">
    <div>I want to use BestFit to... </div>
    <ul><li><input type="checkbox" value="1" name="group[4524][1]" id="mce-group[4524]-4524-0"><label for="mce-group[4524]-4524-0">connect my students/constituents to more resources</label></li>
<li><input type="checkbox" value="2" name="group[4524][2]" id="mce-group[4524]-4524-1"><label for="mce-group[4524]-4524-1">move my service delivery online</label></li>
<li><input type="checkbox" value="4" name="group[4524][4]" id="mce-group[4524]-4524-2"><label for="mce-group[4524]-4524-2">better understand the needs of my students/constituents</label></li>
<li><input type="checkbox" value="8" name="group[4524][8]" id="mce-group[4524]-4524-3"><label for="mce-group[4524]-4524-3">expand capacity of my support staff</label></li>
<li><input type="checkbox" value="16" name="group[4524][16]" id="mce-group[4524]-4524-4"><label for="mce-group[4524]-4524-4">demonstrate impact to my funders</label></li>
</ul>
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_7c623c4916e3b4c6839e07a36_9ab706cd99" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='MMERGE7';ftypes[7]='zip';fnames[8]='MMERGE8';ftypes[8]='text';fnames[9]='MMERGE9';ftypes[9]='dropdown';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->`;

const signupHtml = `<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
  We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://app.us19.list-manage.com/subscribe/post?u=7c623c4916e3b4c6839e07a36&amp;id=9ab706cd99" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
  <div id="mc_embed_signup_scroll">
<h2>Subscribe</h2>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="mc-field-group">
<label for="mce-FNAME">First Name </label>
<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
<label for="mce-LNAME">Last Name </label>
<input type="text" value="" name="LNAME" class="" id="mce-LNAME">
</div>
<div class="mc-field-group size1of2">
<label for="mce-BIRTHDAY-month">Birthday </label>
<div class="datefield">
  <span class="subfield monthfield"><input class="birthday " type="text" pattern="[0-9]*" value="" placeholder="MM" size="2" maxlength="2" name="BIRTHDAY[month]" id="mce-BIRTHDAY-month"></span> / 
  <span class="subfield dayfield"><input class="birthday " type="text" pattern="[0-9]*" value="" placeholder="DD" size="2" maxlength="2" name="BIRTHDAY[day]" id="mce-BIRTHDAY-day"></span> 
  <span class="small-meta nowrap">( mm / dd )</span>
</div>
</div><div class="mc-field-group">
<label for="mce-MMERGE6">University Name (for current students/staff) </label>
<input type="text" value="" name="MMERGE6" class="" id="mce-MMERGE6">
</div>
<div class="mc-field-group">
<label for="mce-MMERGE7">Zip Code </label>
<input type="text" value="" name="MMERGE7" class="" id="mce-MMERGE7">
</div>
<div id="mce-responses" class="clear">
  <div class="response" id="mce-error-response" style="display:none"></div>
  <div class="response" id="mce-success-response" style="display:none"></div>
</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_7c623c4916e3b4c6839e07a36_9ab706cd99" tabindex="-1" value=""></div>
  <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
  </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='MMERGE7';ftypes[7]='zip';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->`;

export const MailchimpForm = () => {
return (
  <FormWrapper 
    type='text/javascript'
    dangerouslySetInnerHTML={{
      __html: partnerHtml,
    }}
  />
);
// return (
//   <StaticQuery
//   query={graphql`
//     query SignupFormText {
//       markdownRemark(fields: { slug: { eq: "/form/signup/" }}) {
//         frontmatter {
//           description
//         }
//       }
//     }
//   `}
//   render={data => {
//     const signupHtml = _.get(data, 'markdownRemark.frontmatter.description');
//     return (
//       <FormWrapper 
//         type='text/javascript'
//         dangerouslySetInnerHTML={{
//           __html: signupHtml,
//         }}
//       />
//     );
//   }}
// />
// )
};

export default MailchimpForm;
