import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
import styled from 'styled-components';
// import HubspotForm from 'react-hubspot-form';
import MailChimpForm from './SignupMailChimp';

const SignUpHeader = styled(Header)`
  color: #CD5B49 !important;
  font-weight: 700 !important;
  text-align: center;
  font-size: 3em !important;
  /* margin-bottom: .5em !important; */
`;

const PartnerDescription = styled.div`
  text-align: center;
  margin-bottom: .5em !important;
  font-size: 1em !important;
`;

export const SignUpForm = ({
  trigger = (<Button inverted color="red">Partner With Us</Button>),
  className,
}) => (<Modal trigger={trigger} className={className}>
  <Modal.Content>
    <SignUpHeader>Partner With Us</SignUpHeader>
      <PartnerDescription>Bring BestFit to your campus or community today! Enter your information and our team will reach out for next steps.</PartnerDescription>
    <MailChimpForm />
    {/* <HubspotForm
      portalId='4359972'
      formId='c8bb6ef6-cbf9-49cf-bfd9-9c55e281a3e3'
      onSubmit={() => console.log('Submit!')}
      onReady={(form) => console.log('Form ready!')}
      loading={<div>Loading...</div>}
    /> */}
  </Modal.Content>
</Modal>);

export default styled(SignUpForm)`
  border-radius: 23px !important;
  .content {
    border-radius: 23px !important;
  }
`;
