import React from 'react'
import {
  Container,
  Grid,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { SubscribeForm } from './styles/SubscribeInput.js';
import footerBackground from '../static/images/footer-background.svg';

import FooterForm from './FooterForm';

// import HubspotForm from 'react-hubspot-form';

// const FooterMessage = styled(Header)`
//   color: #424242;
//   font-size: 20px !important;
//   font-weight: 600 !important;
// `;

const FooterContainer = styled(Container)`
  margin-left: 2.6em !important;
  margin-right: 2.6em !important;
  @media (min-width: 600px) {
    margin-left: 2.6em;
    margin-right: 2.6em;
  }
`;

const FooterSubtext = styled.div`
  margin-top: 1.43em;
  @media (min-width: 993px) {
     margin-top: 2.5em;
  }
  line-height: 18px;
  a {
    color: black !important;
    margin-left: 6px;
    margin-right: 6px;
    text-decoration: underline;
  }
`;

const Footer = () => (
  <Segment vertical 
    style={{ padding: '1.5em 0em', backgroundImage: `url(${footerBackground})`, backgroundSize: 'cover' }}>
    <FooterContainer>
      <Grid doubling stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            {/* <FooterMessage as='h3'>
              Subscribe to our mailing list and receive our Weekly Resource Digest!
            </FooterMessage> */}

            <SubscribeForm>
              {/* <HubspotForm
                portalId='4359972'
                formId='5d899cf9-074c-44a8-93ad-abcb8ebfecae'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
                cssClass='bfForm'
                errorClass='hideError'
                errorMessageClass='bfError'
              /> */}
              <FooterForm />

            </SubscribeForm>
          </Grid.Column>
          <Grid.Column width={3} ></Grid.Column>
          <Grid.Column width={5} verticalAlign='bottom'>
            <div>
              Follow us
            </div>
            <List link horizontal>
              <List.Item as='a' href='//www.facebook.com/findyourbestfitcollege/' target="_blank" >
                <Icon name="facebook" color='black' size='large'></Icon>
              </List.Item>
              <List.Item as='a' href='//instagram.com/bestfit_app' target="_blank">
                <Icon name="instagram" color='black' size='large' />
              </List.Item>
              <List.Item as='a' href='//www.linkedin.com/company/bestfit-app' target="_blank">
                <Icon name="linkedin" color='black' size='large' />
              </List.Item>
              <List.Item as='a' href='//twitter.com/bestfit_app' target="_blank">
                <Icon name="twitter" color='black' size='large' />
              </List.Item>
            </List>
            <FooterSubtext>
              © 2020 BestFit |
              All Rights Reserved |
              <a href="/privacy-policy" target="_blank" title="Privacy Policy">
                Privacy Policy
              </a>|
              <a href="/terms-conditions" target="_blank" title="Terms & Conditions">
                Terms & Conditions
              </a>
            </FooterSubtext>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FooterContainer>
  </Segment>
);

export default Footer;
